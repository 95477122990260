import Action from "calculator/actions/Action";
import Corpse from "calculator/Corpse";
import Heart from "calculator/organs/Heart";
import Brain from "calculator/organs/Brain";
import Intestine from "calculator/organs/Intestine";
import { spareValues } from "calculator/Spare";
import { injectionValues, INJECTION } from "calculator/Injection";
import Organ from "calculator/organs/Organ";

class TreeNode {
    readonly corpse: Corpse;
    readonly heartStorage: Heart[];
    readonly brainStorage: Brain[];
    readonly intestineStorage: Intestine[];
    readonly availableInjections: INJECTION[];
    readonly replaceOrgans: boolean;
    readonly parent?: TreeNode;
    readonly action?: Action;

    brainChanges: Brain[] = [];
    heartChanges: Heart[] = [];
    intestineChanges: Intestine[] = [];

    readonly depth: number = 0;

    constructor(corpse: Corpse,
        heartStorage: Heart[],
        brainStorage: Brain[],
        intestineStorage: Intestine[],
        availableInjections: INJECTION[],
        replaceOrgans: boolean = false,
        brainChanges: Brain[] = [],
        heartChanges: Heart[] = [],
        intestineChanges: Intestine[] = [],
        a?: Action,
        p?: TreeNode) {
        this.corpse = corpse.copy();
        this.heartStorage = heartStorage.map((_e: Heart) => new Heart(_e.red, _e.white, _e.dark));
        this.brainStorage = brainStorage.map((_e: Brain) => new Brain(_e.red, _e.white, _e.dark));
        this.intestineStorage = intestineStorage.map((_e: Intestine) => new Intestine(_e.red, _e.white, _e.dark));
        this.heartChanges = heartChanges.map((_e: Heart) => new Heart(_e.red, _e.white, _e.dark));
        this.brainChanges = brainChanges.map((_e: Brain) => new Brain(_e.red, _e.white, _e.dark));
        this.intestineChanges = intestineChanges.map((_e: Intestine) => new Intestine(_e.red, _e.white, _e.dark));
        this.replaceOrgans = replaceOrgans;
        this.availableInjections = availableInjections;
        this.parent = p;
        this.action = a;
        if (this.action !== undefined) {
            this.action.apply(this);
        }
        if (this.parent) {
            this.depth = this.parent.depth + 1;
        }
    }

    get value() {
        return this.corpse.white - this.corpse.red;
    }

    organValue(storage: Organ[], cur?: Organ) {
        const best = storage.reduce((best, cur) => {
            if (cur.white > best.white) {
                best.white = cur.white;
            }
            if (cur.red < best.red) {
                best.red = cur.red;
            }
            return best;
        }, { red: 0, white: 0 });
        const base = cur === undefined ? { red: 0, white: 0 } : { red: cur.red, white: cur.white };
        const change = { red: best.red - base.red, white: best.white - base.white }
        if (change.white > change.red && change.white >= 0) {
            return change;
        }
        return { red: 0, white: 0 };
    }


    get possibleValue() {
        const spareValue = this.corpse.spares.reduce((total, cur) => {
            if (spareValues.get(cur)![0] >= spareValues.get(cur)![1] && -spareValues.get(cur)![1] > 0) {
                return { red: total.red - spareValues.get(cur)![0], white: total.white - spareValues.get(cur)![1] }
            }
            return total;
        }, { red: 0, white: 0 });
        const injectionValue = this.availableInjections.filter(i => !this.corpse.injections.includes(i)).reduce((total, cur) => {
            if (injectionValues.get(cur)![1] >= injectionValues.get(cur)![0] && injectionValues.get(cur)![1] > 0) {
                return { red: total.red + injectionValues.get(cur)![0], white: total.white + injectionValues.get(cur)![1] }
            }
            return total;
        }, { red: 0, white: 0 });
        //return this.value + injectionValue + spareValue;
        let brainValue = { red: 0, white: 0 };
        let heartValue = { red: 0, white: 0 };
        let intestineValue = { red: 0, white: 0 };
        if (this.replaceOrgans) {
            brainValue = this.organValue(this.brainStorage, this.corpse.brain);
            heartValue = this.organValue(this.heartStorage, this.corpse.heart);
            intestineValue = this.organValue(this.intestineStorage, this.corpse.intestine);
        }

        //console.log(this.value, spareValue, injectionValue, brainValue, heartValue, intestineValue);
        const maxRed = this.corpse.red + spareValue.red + injectionValue.red + brainValue.red + heartValue.red + intestineValue.red;
        const maxWhite = this.corpse.white + spareValue.white + injectionValue.white + brainValue.white + heartValue.white + intestineValue.white;
        //console.log(maxRed, maxWhite);
        //if (maxRed > 0) return 0;
        return Math.min(16, Math.max(0, maxWhite)) - Math.min(16, Math.max(0, maxRed));
    }

    print() {
        if (this.parent) {
            this.parent.print();
        }
        console.log(this.action);
    }

    get path(): TreeNode[] {
        if (this.parent) {
            return [...this.parent.path, this];
        }
        return [this];
    }
}

export default TreeNode;
import React, { useState, useEffect } from 'react';
import Slider from 'react-input-slider';
//@ts-ignore
import Switch from 'react-input-switch';
import './CalculatorInput.scss';
import { INJECTION, ALL_INJECTIONS } from 'calculator/Injection';
import InjectionIcon from 'interface/InjectionIcon';
import Calculator from 'calculator/Calculator';
import TreeNode from 'calculator/tree/TreeNode';
import ActionIcon from './ActionIcon';
import { RedSkull, WhiteSkull, Corpse } from 'interface/icons';

interface Props {
    calculator: Calculator;
}

const CalculatorInput: React.FC<Props> = (props) => {
    const [target, setTarget] = useState<number>(12);
    const [nodes, setNodes] = useState<number>(5000);
    const [depth, setDepth] = useState<number>(10);
    const [replaceOrgans, setReplaceOrgans] = useState<boolean>(true);
    const [injections, setInjections] = useState<INJECTION[]>(ALL_INJECTIONS);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [reachable, setReachable] = useState<boolean>(true);
    const [path, setPath] = useState<TreeNode[] | undefined>(undefined);
    const [calculating, setCalculating] = useState<boolean>(false);
    useEffect(() => {
        async function calc() {
            setCalculating(false);
            const p = await props.calculator.calculate(target, replaceOrgans, injections, nodes, depth);
            setDisabled(false);
            setPath(p.path);
        }
        if (calculating) {
            calc();
        }
    }, [calculating, target, replaceOrgans, injections, nodes, depth, props.calculator]);
    return (<><div className="calculator-input">
        <h4 className="calculator-input-header">
            Calculator <small><small>v {props.calculator.version}</small></small>
        </h4>
        <div className="calculator-input-body">
            <div className="calculator-input-target">Target: {target}<br />
                <Slider
                    axis="x"
                    xmin={0}
                    xmax={16}
                    x={target}
                    onChange={({ x }) => setTarget(x)}
                    styles={{
                        active: {
                            backgroundColor: '#8B1801'
                        }
                    }}
                />
            </div>
            <div className="calculator-input-max">Max Nodes: {nodes}<br />
                <Slider
                    axis="x"
                    xmin={0}
                    xmax={10000}
                    x={nodes}
                    onChange={({ x }) => setNodes(x)}
                    styles={{
                        active: {
                            backgroundColor: '#8B1801'
                        }
                    }}
                />
            </div>
            <div className="calculator-input-depth">Max Depth: {depth}<br />
                <Slider
                    axis="x"
                    xmin={1}
                    xmax={20}
                    x={depth}
                    onChange={({ x }) => setDepth(x)}
                    styles={{
                        active: {
                            backgroundColor: '#8B1801'
                        }
                    }}
                />
            </div>
            <div className="calculator-input-organs">Replace Organs:<br />
                <Switch
                    value={+replaceOrgans}
                    onChange={setReplaceOrgans}
                    styles={{
                        trackChecked: {
                            backgroundColor: '#8B1801'
                        }
                    }}
                />
            </div>
            <div className="calculator-input-injections">Available Injections:<br />
                {ALL_INJECTIONS.map((injection: INJECTION) =>
                    <div
                        key={injection}
                        className={"injection" + (injections.includes(injection) ? "" : " removed")}
                        onClick={() => {
                            if (injections.includes(injection)) {
                                setInjections([...injections.filter(i => i !== injection)]);
                            } else {
                                setInjections([...injections, injection]);
                            }
                        }} >
                        <InjectionIcon injection={injection} />
                    </div>)}
            </div>
            <div
                className={"calculator-input-calculate" + (disabled ? " disabled" : "")}
                onClick={async () => {
                    props.calculator.abort();
                    setDisabled(true);
                    setReachable(true);
                    setPath(undefined);
                    if (props.calculator.eval(target, replaceOrgans, injections) < target) {
                        setReachable(false);
                        setDisabled(false);
                    } else {
                        setCalculating(true);
                    }
                }}
            >
                {calculating ? 'Calculating' : "Calculate"}
            </div>
            <div className="calculator-input-message">
                {!reachable && <small><small><small>Target might not be reachable</small></small></small>}
            </div>
            <div className="calculator-input-path">

            </div>
        </div>
    </div>
        {path !== undefined && <div className="action-container">
            <h4 className="action-container-header">Actions</h4>
            <div className="action-container-body">
                {path.filter(n => n.action !== undefined).map((n: TreeNode, idx: number) => <div key={idx} className="action"><ActionIcon action={n.action!} /></div>)}
            </div>
            <div className="action-container-footer">
                {path[path.length - 1].corpse.red}<RedSkull />
                <Corpse />
                <WhiteSkull />{path[path.length - 1].corpse.white}
            </div>
        </div>}</>);
};

export default CalculatorInput;
import React, { useState } from 'react';
import Calculator from 'calculator/Calculator';
import OrganStorageInput from './OrganStorageInput';
import useForceUpdate from '../forceUpdate';
import { OrganType } from 'calculator/organs/Organ';

import './OrganStorageContainer.scss';

interface Props {
    calculator: Calculator;
}

const renderOrganTab = (organType: OrganType, calculator: Calculator, forceUpdate: () => void) => {
    switch (organType) {
        case OrganType.BRAIN:
            return <OrganStorageInput
                organs={calculator.brainStorage}
                forceUpdate={() => { forceUpdate(); calculator.saveBrainStorage() }}
                removeOrgan={calculator.removeBrain}
                addOrgan={calculator.addBrain}
            />;
        case OrganType.HEART:
            return <OrganStorageInput
                organs={calculator.heartStorage}
                forceUpdate={() => { forceUpdate(); calculator.saveHeartStorage() }}
                removeOrgan={calculator.removeHeart}
                addOrgan={calculator.addHeart}
            />;
        case OrganType.INTESTINE:
            return <OrganStorageInput
                organs={calculator.intestineStorage}
                forceUpdate={() => { forceUpdate(); calculator.saveIntestineStorage() }}
                removeOrgan={calculator.removeIntestine}
                addOrgan={calculator.addIntestine}
            />;
    }

}

const OrganStorageContainer: React.FC<Props> = (props) => {
    const forceUpdate = useForceUpdate();
    const [organType, setOrganType] = useState<OrganType>(OrganType.BRAIN);
    return <div className="organ-storage">
        <div className="organ-storage-header">
            <h4 className="organ-storage-title">
                Organ Storage
            </h4>
            <div className="organ-storage-tabs">
                <div
                    className={"organ-storage-tab" + (organType === OrganType.BRAIN ? " active" : "")}
                    onClick={() => { setOrganType(OrganType.BRAIN) }}>
                    Brain
                </div>
                <div
                    className={"organ-storage-tab" + (organType === OrganType.HEART ? " active" : "")}
                    onClick={() => { setOrganType(OrganType.HEART) }}>
                    Heart
                </div>
                <div
                    className={"organ-storage-tab" + (organType === OrganType.INTESTINE ? " active" : "")}
                    onClick={() => { setOrganType(OrganType.INTESTINE) }}>
                    Intestine
                </div>
            </div>
        </div>
        <div className="organ-storage-body">
            {renderOrganTab(organType, props.calculator, forceUpdate)}
        </div>
    </div>;
};

export default OrganStorageContainer;
import React from 'react';
import Organ, { OrganType } from 'calculator/organs/Organ';
import { DarkBrain, Brain, DarkHeart, Heart, DarkIntestine, Intestine } from 'interface/icons';
interface Props {
    organ: Organ;
}

const organMap: Map<OrganType, (dark: boolean) => JSX.Element> = new Map<OrganType, (dark: boolean) => JSX.Element>([
    [OrganType.BRAIN, (dark: boolean) => dark ? <DarkBrain /> : <Brain />],
    [OrganType.HEART, (dark: boolean) => dark ? <DarkHeart /> : <Heart />],
    [OrganType.INTESTINE, (dark: boolean) => dark ? <DarkIntestine /> : <Intestine />],
]);

const OrganIcon: React.FC<Props> = (props) => {
    return <>{organMap.get(props.organ.type)!(props.organ.dark)}</>;
};

export default OrganIcon;
import React from 'react';
import Action from "calculator/actions/Action";
import OrganAction from 'calculator/actions/OrganAction';
import OrganIcon from 'interface/organinput/OrganIcon';
import AddInjectionAction from 'calculator/actions/AddInjection';
import InjectionIcon from 'interface/InjectionIcon';
import RemoveSpareAction from 'calculator/actions/RemoveSpare';
import SpareIcon from 'interface/corpseinput/SpareIcon';
import AddOrganAction from 'calculator/actions/AddOrgan';
import { RedSkull, WhiteSkull } from 'interface/icons';

interface Props {
    action: Action;
}

const ActionIcon: React.FC<Props> = (props) => {
    if (props.action instanceof AddOrganAction) {
        const organ = (props.action as OrganAction).organ!;
        return <><span>Put in</span><OrganIcon organ={organ} /><span>({organ.red}</span><RedSkull /><span>/ {organ.white}</span> <WhiteSkull /><span>)</span></>;
    }
    if (props.action instanceof AddInjectionAction) {
        return <><span>Apply</span><InjectionIcon injection={(props.action as AddInjectionAction).injection} /></>;
    }
    if (props.action instanceof RemoveSpareAction) {
        return <><span>Remove</span><SpareIcon spare={(props.action as RemoveSpareAction).spare} /></>;
    }
    return null;
};

export default ActionIcon;
import React from 'react';

import { SPARE } from 'calculator/Spare';
import { Blood, Fat, Flesh, Skull, Skin, Bone } from '../icons/spares';
interface Props {
    spare: SPARE;
}

const spareMap: Map<SPARE, JSX.Element> = new Map<SPARE, JSX.Element>([
    [SPARE.BLOOD, <Blood />],
    [SPARE.BONE, <Bone />],
    [SPARE.FAT, <Fat />],
    [SPARE.FLESH, <Flesh />],
    [SPARE.SKULL, <Skull />],
    [SPARE.SKIN, <Skin />]
]);

const SpareIcon: React.FC<Props> = (props) => spareMap.get(props.spare)!;

export default SpareIcon;
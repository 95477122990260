import AddOrganAction from "../AddOrgan";
import TreeNode from "calculator/tree/TreeNode";

class AddBrainAction extends AddOrganAction {
    apply(t: TreeNode): void {
        if (t.corpse.brain) t.brainStorage.push(t.corpse.brain);
        t.brainStorage.splice(t.brainStorage.findIndex(e => e.red === this.organ.red && e.white === this.organ.white && e.dark === this.organ.dark), 1);
        t.corpse.brain = this.organ;
        t.brainChanges.push(this.organ);
    }
}

export default AddBrainAction;
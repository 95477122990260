import Organ from "calculator/organs/Organ";
import OrganAction from "./OrganAction";

abstract class AddOrganAction extends OrganAction {
    readonly organ: Organ;
    constructor(o: Organ) {
        super();
        this.organ = o;
    }
}

export default AddOrganAction;
import React from 'react';
import './SkullInput.scss';
import { Plus, Minus } from '../icons';
interface Props {
    skulls: number;
    onChange: (skulls: number) => void,
}

const SkullInput: React.FC<Props> = (props) => {

    return (
        <div className="skull-input">
            <div className="minus-button btn" onClick={() => { props.onChange(props.skulls - 1) }}><Minus tooltip="Decrease" /></div>
            <span className="value">{props.skulls > 0 && '+'}{props.skulls}{props.children}</span>
            <div className="plus-button btn" onClick={() => { props.onChange(props.skulls + 1) }}><Plus tooltip="Increase" /></div>
        </div>
    );
};

export default SkullInput;
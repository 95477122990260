export enum INJECTION {
    LYE,
    ACID,
    GLUE,
    DARK,
    SILVER,
    GOLD,
};

export const ALL_INJECTIONS: INJECTION[] = Object.values(INJECTION).filter(x => typeof x === "number") as Array<INJECTION>;
export const injectionValues: Map<INJECTION, number[]> = new Map<INJECTION, number[]>([
    [INJECTION.LYE, [1, 1]],
    [INJECTION.ACID, [-1, -1]],
    [INJECTION.GLUE, [0, 1]],
    [INJECTION.DARK, [2, 0]],
    [INJECTION.SILVER, [-1, 1]],
    [INJECTION.GOLD, [-2, 2]],
]);

export const allInjections = () => Array.from(ALL_INJECTIONS);
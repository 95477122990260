import React from 'react';


interface Props {
    tooltip?: string;
}

abstract class Icon extends React.Component<Props> {
    abstract readonly alt: string;
    abstract readonly file: string;

    render() {
        return <img src={this.file} alt={this.alt} title={this.props.tooltip || this.alt} />;
    }
}

export default Icon;
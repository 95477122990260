import Heart from "./organs/Heart";
import Brain from "./organs/Brain";
import Intestine from "./organs/Intestine";
import { allSpares, SPARE, spareValues } from "./Spare";
import { INJECTION, injectionValues } from "./Injection";

class Corpse {
    private _heart?: Heart = new Heart();
    private _brain?: Brain = new Brain();
    private _intestine?: Intestine = new Intestine();
    private _spares: SPARE[] = allSpares();
    private _injections: INJECTION[] = [];

    set heart(h: Heart | undefined) {
        this._heart = h;
    }

    get heart() {
        return this._heart;
    }

    set brain(b: Brain | undefined) {
        this._brain = b;
    }

    get brain() {
        return this._brain;
    }

    set intestine(i: Intestine | undefined) {
        this._intestine = i;
    }

    get intestine() {
        return this._intestine;
    }

    get spares() {
        return this._spares;
    }

    get injections() {
        return this._injections;
    }

    get red() {
        let red = 0;
        if (this._heart !== undefined) {
            red += this._heart.red;
        }
        if (this._brain !== undefined) {
            red += this._brain.red;
        }
        if (this._intestine !== undefined) {
            red += this._intestine.red;
        }
        red += this._spares.reduce((total, cur) => total + spareValues.get(cur)![0], 0);
        red += this._injections.reduce((total, cur) => total + injectionValues.get(cur)![0], 0);
        return Math.min(16, Math.max(red, 0));
    }

    get white() {
        let white = 0;
        if (this._heart !== undefined) {
            white += this._heart.white;
        }
        if (this._brain !== undefined) {
            white += this._brain.white;
        }
        if (this._intestine !== undefined) {
            white += this._intestine.white;
        }
        white += this._spares.reduce((total, cur) => total + spareValues.get(cur)![1], 0);
        white += this._injections.reduce((total, cur) => total + injectionValues.get(cur)![1], 0);
        return Math.min(16, Math.max(white, 0));
    }

    toggleSpare(spare: SPARE) {
        if (this._spares.includes(spare)) {
            this._spares.splice(this._spares.indexOf(spare), 1);
        } else {
            this._spares.push(spare);
        }
    }

    toggleInjection(injection: INJECTION) {
        if (this._injections.includes(injection)) {
            this._injections.splice(this._injections.indexOf(injection), 1);
        } else {
            this._injections.push(injection);
        }
    }

    addInjection(injection: INJECTION) {
        this._injections.push(injection);
    }

    copy() {
        const c = new Corpse();
        if (this._heart) {
            c._heart = new Heart(this._heart.red, this._heart.white, this._heart.dark);
        } else {
            c._heart = undefined;
        }

        if (this._brain) {
            c._brain = new Brain(this._brain.red, this._brain.white, this._brain.dark);
        } else {
            c._brain = undefined;
        }

        if (this._intestine) {
            c._intestine = new Intestine(this._intestine.red, this._intestine.white, this._intestine.dark);
        } else {
            c._intestine = undefined;
        }

        c._spares = Array.from(this._spares);
        c._injections = Array.from(this._injections);

        return c;
    }
}

export default Corpse;
import React from 'react';

import { INJECTION } from 'calculator/Injection';
import { DarkInjection, GoldInjection, SilverInjection, GlueInjection, LyeInjection } from './icons';
import AcidInjection from './icons/injections/AcidInjection';
interface Props {
    injection: INJECTION;
}

const injectionMap: Map<INJECTION, JSX.Element> = new Map<INJECTION, JSX.Element>([
    [INJECTION.LYE, <LyeInjection />],
    [INJECTION.ACID, <AcidInjection />],
    [INJECTION.DARK, <DarkInjection />],
    [INJECTION.GOLD, <GoldInjection />],
    [INJECTION.SILVER, <SilverInjection />],
    [INJECTION.GLUE, <GlueInjection />],

]);

const InjectionIcon: React.FC<Props> = (props) => injectionMap.get(props.injection)!;

export default InjectionIcon;
export enum SPARE {
    BLOOD,
    BONE,
    FAT,
    FLESH,
    SKULL,
    SKIN
};

export const ALL_SPARES: SPARE[] = Object.values(SPARE).filter(x => typeof x === "number") as Array<SPARE>;
export const spareValues: Map<SPARE, number[]> = new Map<SPARE, number[]>([
    [SPARE.BLOOD, [1, -1]],
    [SPARE.BONE, [0, 0]],
    [SPARE.FAT, [1, -1]],
    [SPARE.FLESH, [0, 1]],
    [SPARE.SKULL, [-1, 0]],
    [SPARE.SKIN, [-1, 1]]
]);

export const allSpares = () => Array.from(ALL_SPARES);
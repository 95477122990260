import Corpse from "./Corpse";
import Heart from "./organs/Heart";
import Brain from "./organs/Brain";
import Intestine from "./organs/Intestine";
import TreeNode from "./tree/TreeNode";
import Action from "./actions/Action";
import AddHeartAction from "./actions/organ/AddHeart";
import AddBrainAction from "./actions/organ/AddBrain";
import AddIntestineAction from "./actions/organ/AddIntestine";
import RemoveSpareAction from "./actions/RemoveSpare";
import AddInjectionAction from "./actions/AddInjection";

import { ALL_SPARES, SPARE, spareValues } from "./Spare";
import { INJECTION, injectionValues } from "./Injection";

const MAX_ORGAN_CHANGES = 1;

function arraysEqual(a: number[], b: number[]): boolean {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    return a.filter((n: number) => !b.includes(n)).length === 0
        && b.filter((n: number) => !a.includes(n)).length === 0;
}

class Calculator {
    readonly corpse: Corpse = new Corpse();
    readonly heartStorage: Heart[] = [];
    readonly brainStorage: Brain[] = [];
    readonly intestineStorage: Intestine[] = [];
    readonly version: string = "1.1.2";
    constructor() {

        const storedHeart = localStorage.getItem('heartStorage');
        if (storedHeart !== null) {
            this.heartStorage = JSON.parse(storedHeart).map((item: any) => new Heart(item.redSkulls, item.whiteSkulls, item._dark));
        }

        const storedBrain = localStorage.getItem('brainStorage');
        if (storedBrain !== null) {
            this.brainStorage = JSON.parse(storedBrain).map((item: any) => new Brain(item.redSkulls, item.whiteSkulls, item._dark));
        }
        const storedIntestine = localStorage.getItem('intestineStorage');
        if (storedIntestine !== null) {
            this.intestineStorage = JSON.parse(storedIntestine).map((item: any) => new Intestine(item.redSkulls, item.whiteSkulls, item._dark));
        }

        this.removeBrain = this.removeBrain.bind(this);
        this.removeHeart = this.removeHeart.bind(this);
        this.removeIntestine = this.removeIntestine.bind(this);
        this.addBrain = this.addBrain.bind(this);
        this.addHeart = this.addHeart.bind(this);
        this.addIntestine = this.addIntestine.bind(this);
    }

    private abortCalculation: boolean = false;

    abort() {
        this.abortCalculation = true;
    }

    eval(goal: number, replaceOrgans: boolean = false, availableInjections: INJECTION[] = []) {
        const root: TreeNode = new TreeNode(this.corpse, this.heartStorage, this.brainStorage, this.intestineStorage, availableInjections, replaceOrgans);
        return root.possibleValue;
    }

    async calculate(goal: number, replaceOrgans: boolean = false, availableInjections: INJECTION[] = [], maxNodes: number = 2000, maxDepth = 10) {
        this.abortCalculation = false;
        const root: TreeNode = new TreeNode(this.corpse, this.heartStorage, this.brainStorage, this.intestineStorage, availableInjections, replaceOrgans);
        const actionFilter = (a: Action) => {
            if (a instanceof RemoveSpareAction) {
                const val = spareValues.get((a as RemoveSpareAction).spare)!;
                if (val[0] === 0 && val[1] === 0) {
                    return false;
                }
            }
            return true;
        };
        let visited: TreeNode[] = [];
        const childrenFilter = (n: TreeNode) => {
            return undefined === visited.find((v: TreeNode) => {
                //check if any organ differs
                if (!!v.corpse.brain !== !!n.corpse.brain) return false;
                if (v.brainChanges.length !== n.brainChanges.length) return false;
                if (v.corpse.brain !== undefined && n.corpse.brain !== undefined) {
                    if (v.corpse.brain.red !== n.corpse.brain.red || v.corpse.brain.white !== n.corpse.brain.white) {
                        return false;
                    }
                }
                if (!!v.corpse.heart !== !!n.corpse.heart) return false;
                if (v.heartChanges.length !== n.heartChanges.length) return false;
                if (v.corpse.heart !== undefined && n.corpse.heart !== undefined) {
                    if (v.corpse.heart.red !== n.corpse.heart.red || v.corpse.heart.white !== n.corpse.heart.white) {
                        return false;
                    }
                }
                if (!!v.corpse.intestine !== !!n.corpse.intestine) return false;
                if (v.intestineChanges.length !== n.intestineChanges.length) return false;
                if (v.corpse.intestine !== undefined && n.corpse.intestine !== undefined) {
                    if (v.corpse.intestine.red !== n.corpse.intestine.red || v.corpse.intestine.white !== n.corpse.intestine.white) {
                        return false;
                    }
                }
                return arraysEqual(v.corpse.spares, n.corpse.spares) && arraysEqual(v.corpse.injections, n.corpse.injections);

            });
        };
        let cur: TreeNode;
        let stack: TreeNode[] = this.createChildren(root, this.possibleActions(root).filter(actionFilter));
        let best: TreeNode = root;
        while (stack.length !== 0 && (best.corpse.white < goal || best.corpse.red > 0) && !this.abortCalculation) {
            stack.sort((a: TreeNode, b: TreeNode) => {
                if (a.corpse.white === b.corpse.white) return a.depth - b.depth;
                return b.value - a.value;
            });
            if (maxNodes > 0 && visited.length === maxNodes) {
                break;
            }
            cur = stack.shift()!;
            visited.push(cur);
            //console.log(visited.length.toString().padStart(5), " / ", (stack.length + visited.length).toString().padStart(5));
            if (cur.value > best.value) {
                best = cur;
            }
            if (cur.depth < maxDepth && cur.possibleValue >= goal) {
                stack.push(...this.createChildren(cur, this.possibleActions(cur).filter(actionFilter)).filter(childrenFilter));
            }
        };
        console.log(visited.length);
        //best.print();
        return best;
    }

    createChildren(n: TreeNode, actions: Action[]): TreeNode[] {
        return actions.map((action: Action) => new TreeNode(n.corpse, n.heartStorage, n.brainStorage, n.intestineStorage, n.availableInjections, n.replaceOrgans, n.brainChanges, n.heartChanges, n.intestineChanges, action, n));
    }

    getChildren(n: TreeNode): TreeNode[] {
        const actions: Action[] = this.possibleActions(n);
        return actions.map((action: Action) => new TreeNode(n.corpse, n.heartStorage, n.brainStorage, n.intestineStorage, n.availableInjections, n.replaceOrgans, n.brainChanges, n.heartChanges, n.intestineChanges, action, n));
    }

    possibleActions(n: TreeNode): Action[] {
        let actions: Action[] = [];


        n.availableInjections.forEach((i: INJECTION) => {
            if (!n.corpse.injections.includes(i)) {
                if (n.corpse.red >= (-injectionValues.get(i)![0])) {
                    if (i === INJECTION.DARK) {
                        if (

                            (n.corpse.red < 2 && !n.corpse.injections.includes(INJECTION.GOLD) && n.availableInjections.includes(INJECTION.GOLD))
                            ||
                            (n.corpse.red < 1 && !n.corpse.injections.includes(INJECTION.SILVER) && n.availableInjections.includes(INJECTION.SILVER))
                        ) {
                            actions.push(new AddInjectionAction(i));
                        }
                    } else {
                        actions.push(new AddInjectionAction(i));
                    }

                }
            }
        });
        ALL_SPARES.forEach((s: SPARE) => {
            if (n.corpse.spares.includes(s)) {
                actions.push(new RemoveSpareAction(s));
            } else {
                //actions.push(new AddSpareAction(s));
            }
        });


        if (n.replaceOrgans) {
            if (n.heartChanges.length < MAX_ORGAN_CHANGES) {
                if (!n.action || !(n.action instanceof AddHeartAction)) {
                    if (n.corpse.heart !== undefined) {
                        //actions.push(new RemoveHeartAction());
                    }
                    let tmp_actions: AddHeartAction[] = [];
                    n.heartStorage.forEach((heart: Heart) => {
                        if (!n.heartChanges.find(i => i.red === heart.red && i.white === heart.white) &&
                            !tmp_actions.find(i => i.organ.red === heart.red && i.organ.white === heart.white) &&
                            !(n.corpse.heart && n.corpse.heart.red === heart.red && n.corpse.heart.white === heart.white)) {
                            tmp_actions.push(new AddHeartAction(heart));
                        }
                    });
                    actions.push(...tmp_actions);
                }
            }

            if (n.brainChanges.length < MAX_ORGAN_CHANGES) {
                if (!n.action || !(n.action instanceof AddBrainAction)) {
                    if (n.corpse.brain !== undefined) {
                        //actions.push(new RemoveBrainAction());
                    }
                    let tmp_actions: AddBrainAction[] = [];
                    n.brainStorage.forEach((brain: Brain) => {
                        if (!n.brainChanges.find(i => i.red === brain.red && i.white === brain.white) &&
                            !tmp_actions.find(i => i.organ.red === brain.red && i.organ.white === brain.white) &&
                            !(n.corpse.brain && n.corpse.brain.red === brain.red && n.corpse.brain.white === brain.white)) {
                            tmp_actions.push(new AddBrainAction(brain));
                        }
                    });
                    actions.push(...tmp_actions);
                }
            }

            if (n.intestineChanges.length < MAX_ORGAN_CHANGES) {
                if (!n.action || !(n.action instanceof AddIntestineAction)) {
                    if (n.corpse.intestine !== undefined) {
                        //actions.push(new RemoveIntestineAction());
                    }
                    let tmp_actions: AddIntestineAction[] = [];
                    n.intestineStorage.forEach((intestine: Intestine) => {
                        if (!n.intestineChanges.find(i => i.red === intestine.red && i.white === intestine.white) &&
                            !tmp_actions.find(i => i.organ.red === intestine.red && i.organ.white === intestine.white) &&
                            !(n.corpse.intestine && n.corpse.intestine.red === intestine.red && n.corpse.intestine.white === intestine.white)) {
                            tmp_actions.push(new AddIntestineAction(intestine));
                        }
                    });
                    actions.push(...tmp_actions);
                }
            }

        }
        if (n.depth === 0) console.log(actions);
        return actions;
    }


    removeHeart(idx: number) {
        this.heartStorage.splice(idx, 1);
        this.saveHeartStorage();
    }

    addHeart() {
        this.heartStorage.push(new Heart());
    }

    removeBrain(idx: number) {
        this.brainStorage.splice(idx, 1);
        this.saveBrainStorage();
    }

    addBrain() {
        this.brainStorage.push(new Brain());
    }

    removeIntestine(idx: number) {
        this.intestineStorage.splice(idx, 1);
        this.saveIntestineStorage();
    }

    addIntestine() {
        this.intestineStorage.push(new Intestine());
    }

    saveHeartStorage() {
        localStorage.setItem('heartStorage', JSON.stringify(this.heartStorage));
    }

    saveBrainStorage() {
        localStorage.setItem('brainStorage', JSON.stringify(this.brainStorage));
    }

    saveIntestineStorage() {
        localStorage.setItem('intestineStorage', JSON.stringify(this.intestineStorage));
    }

}

export default Calculator;
import Action from "./Action";
import { INJECTION } from "calculator/Injection";
import TreeNode from "calculator/tree/TreeNode";

class AddInjectionAction extends Action {
    readonly injection: INJECTION;
    constructor(i: INJECTION) {
        super();
        this.injection = i;
    }

    apply(t: TreeNode): void {
        t.corpse.addInjection(this.injection);
    }

}

export default AddInjectionAction;
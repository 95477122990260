const limit = 4;

const clamp = (value: number) => { return Math.min(Math.max(value, -limit), limit) };

abstract class Organ {
    private redSkulls: number = 0;
    private whiteSkulls: number = 0;
    private _dark: boolean = false;
    abstract readonly type: OrganType;

    constructor(red: number = 0, white: number = 0, dark: boolean = false) {
        this._dark = dark;
        this.redSkulls = red;
        this.whiteSkulls = white;
    }

    toggleDark() {
        this._dark = !this._dark;
        if (this._dark) {
            //this.redSkulls = 4;
            //this.whiteSkulls = 1;
        }
    }

    get dark() {
        return this._dark;
    }

    set red(skulls: number) {
        this.redSkulls = clamp(skulls);
    }

    get red() {
        return this.redSkulls;
    }

    set white(skulls: number) {
        this.whiteSkulls = clamp(skulls);
    }

    get white() {
        return this.whiteSkulls;
    }
}
export enum OrganType {
    BRAIN,
    HEART,
    INTESTINE,
};

export default Organ;
import React, { useState } from 'react';
import './App.scss';
import CorpseInput from './interface/corpseinput/CorpseInput';
import Calculator from 'calculator/Calculator';
import OrganStorageContainer from 'interface/organstorage/OrganStorageContainer';
import CalculatorInput from 'interface/calculator/CalculatorInput';

const App: React.FC = () => {
  const [calculator] = useState<Calculator>(new Calculator());
  return (
    <div className="App">
      <header className="App-header">
        <OrganStorageContainer calculator={calculator} />
        <CorpseInput corpse={calculator.corpse} />
        <CalculatorInput calculator={calculator} />
      </header>
    </div>
  );
}

export default App;

import React from 'react';
import Organ from 'calculator/organs/Organ';
import OrganInput from '../organinput/OrganInput';
import { Minus, Plus } from '../icons';
import './OrganStorageInput.scss';
interface Props {
    organs: Organ[];
    forceUpdate: () => void;
    removeOrgan: (idx: number) => void;
    addOrgan: () => void;
}

const OrganStorageInput: React.FC<Props> = (props) => {
    return <>
        {props.organs.map((organ: Organ, key: number) => <div key={key} className="organ-row">
            <div className="organ-remove" onClick={() => { props.removeOrgan(key); props.forceUpdate(); }}><Minus /></div>
            <OrganInput organ={organ} forceUpdate={props.forceUpdate} />
        </div>)}
        <div className="organ-row"><div className="organ-add" onClick={() => { props.addOrgan(); props.forceUpdate(); }}><Plus /></div></div>
    </>;
};

export default OrganStorageInput;
import React from 'react';
import CorpseIcon from 'interface/icons/organs/Corpse';
import WhiteSkullIcon from 'interface/icons/WhiteSkull';
import RedSkullIcon from 'interface/icons/RedSkull';
import OrganInput from '../organinput/OrganInput';
import Corpse from 'calculator/Corpse';
import './CorpseInput.scss';
import useForceUpdate from '../forceUpdate';
import { ALL_SPARES, SPARE } from 'calculator/Spare';
import SpareIcon from './SpareIcon';
import { ALL_INJECTIONS, INJECTION } from 'calculator/Injection';
import InjectionIcon from '../InjectionIcon';

interface Props {
    corpse: Corpse;
};

const CorpseInput: React.FC<Props> = (props) => {
    const forceUpdate = useForceUpdate();
    return (
        <div className="corpse-input">
            <h4 className="corpse-input-header">
                <div>
                    {props.corpse.red}<RedSkullIcon />
                    <CorpseIcon />
                    <WhiteSkullIcon />{props.corpse.white}
                </div>
                <div>Corpse Input</div>
            </h4>
            <div className="corpse-input-body">
                <OrganInput organ={props.corpse.brain!} forceUpdate={forceUpdate} />
                <OrganInput organ={props.corpse.heart!} forceUpdate={forceUpdate} />
                <OrganInput organ={props.corpse.intestine!} forceUpdate={forceUpdate} />

            </div>
            <div className="spares-input">{ALL_SPARES.map((spare: SPARE) =>
                <div key={spare} className={"spare" + (props.corpse.spares.includes(spare) ? "" : " removed")} onClick={() => { props.corpse.toggleSpare(spare); forceUpdate(); }} >
                    <SpareIcon spare={spare} />
                </div>
            )}
            </div>
            <div className="injections-input">{ALL_INJECTIONS.map((injection: INJECTION) =>
                <div key={injection} className={"injection" + (props.corpse.injections.includes(injection) ? "" : " removed")} onClick={() => { props.corpse.toggleInjection(injection); forceUpdate(); }} >
                    <InjectionIcon injection={injection} />
                </div>
            )}
            </div>
        </div>
    );
};

export default CorpseInput;
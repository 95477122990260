import Action from "./Action";
import { SPARE } from "calculator/Spare";
import TreeNode from "calculator/tree/TreeNode";

class RemoveSpareAction extends Action {
    readonly spare: SPARE;
    constructor(s: SPARE) {
        super();
        this.spare = s;
    }

    apply(t: TreeNode): void {
        t.corpse.toggleSpare(this.spare);
    }
}

export default RemoveSpareAction;
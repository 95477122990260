import React from 'react';
import Organ from 'calculator/organs/Organ';
import OrganIcon from './OrganIcon';
import './OrganInput.scss';
import SkullInput from './SkullInput';
import { RedSkull, WhiteSkull } from '../icons';
interface Props {
    organ: Organ;
    forceUpdate: () => void;
}

const OrganInput: React.FC<Props> = (props) => {
    return (
        <div className="organ">
            <div className="organ-icon" onClick={_ => { props.organ.toggleDark(); props.forceUpdate(); }} ><OrganIcon organ={props.organ} /></div>
            <div className="red-skull"><SkullInput skulls={props.organ.red} onChange={(skulls: number) => { props.organ.red = skulls; props.forceUpdate(); }} ><RedSkull /></SkullInput></div>
            <div className="white-skull"><SkullInput skulls={props.organ.white} onChange={(skulls: number) => { props.organ.white = skulls; props.forceUpdate(); }} ><WhiteSkull /></SkullInput></div>
        </div>
    );
};

export default OrganInput;